/// <reference path="../../typings/index.d.ts" />

$(function() {
     //скролл
    if($('.scroll').length) {
        $('.scroll').click(function(e) {
            e.preventDefault();
            let scroll_el = $(this).attr('href'); 
            if ($(scroll_el).length != 0) { 
                if ($(window).width() >= 1280) {
                    $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500); 
                } else {
                    $('html, body').animate({ scrollTop: $(scroll_el).offset().top -40}, 500);                     
                }
            }
        });
    }

    if($('.fancybox').length) {
        $(".fancybox").fancybox();
    }

    $('input[name="email"]').mask("A", {
        translation: {
            "A": { pattern: /[\w@\-.+]/, recursive: true }
        }
    });

    $('input[name="phone"]').focus(function() {
        if($(this).val() == "") {
            $(this).val('+7 (');
        }   
      });

      $('input[name="phone"]').focusout(function() {
          $(this).mask("+7 (000) 000-00-00", {
            clearIfNotMatch: true
          });
      });

    $('input[name="phone"]').bind("change keyup input", function() { 

        var num = $(this).val().replace(/\D+/g,""); 
        
        if(num.indexOf("789") === 0 || num.indexOf("77") === 0 || num.indexOf("89") === 0 || num === "" || num === "7") {

            if(num.indexOf("789") === 0 || num.indexOf("77") === 0) {    
                num = num.substr(2 - num.length, num.length - 2);
            }

            if(num.indexOf("89") === 0) { 
                num = num.substr(1 - num.length, num.length - 1);
            }

            if(num === "7") {
                num = "";
            }

            num = "+7 (" + num;
            $(this).val(num);
            $(this).mask("+7 (000) 000-00-00", {
                clearIfNotMatch: true
              });
            var b = $(this).val();
            $(this).val(b);
        }


    });
      
    $('input[name="phone"]').mask("+7 (000) 000-00-00", {
        clearIfNotMatch: true
    });

    $('.min-price, .max-price').mask('000000000', {reverse: true});

    

    $('.popup_show').click(function(e) {
        e.preventDefault();

        if($(this).attr('href') == "#click") {
            let offer_id = $(this).closest('section.item').find('.offer_sizes .size.active').attr('data-offer-id');
            let item_id = $(this).closest('section.item').find('.offer_sizes .size.active').attr('data-item-id');
            eval('item_'+item_id+'.update_item('+offer_id+')');
        }

        $($(this).attr('href')).addClass('show');

        $('.popup_background').addClass('show');
    });

    $('.popup .close_button').click(function(e) {
        e.preventDefault();

        closePopups();
    });

    $('.popup_background').click(function(e) {
        e.preventDefault();

        closePopups();
    });


    if($('.seo_text').length) {
        setTimeout(function () {
            let seo_height = $('.seo_text .preview').height() + "px";
            console.log(seo_height);
            $('.seo_text .preview').addClass('short');
            $('.seo_text .preview').css({'maxHeight': '100px'});
            $('.show_full_seo').click(function(e) {
                e.preventDefault();
                if(!$(this).siblings('.preview').hasClass('short')) {
                    $(this).siblings('.preview').addClass('short');            
                    $(this).siblings('.preview').animate({'maxHeight': '100px'}, 100);
                    $(this).html('Показать полностью');
                } else {
                    $(this).siblings('.preview').removeClass('short');
                    $(this).siblings('.preview').animate({'maxHeight': seo_height}, 100);
                    $(this).html('Скрыть');
                }
            });
        }, 1000);       
        
        
    }

    if($('.delivery .radio').length) { 
        $('.delivery .radio input').change(function() {
            changedDelivery(this);
        });
    }

    if($('.delivery_popup').length) {
        $('.delivery_popup .nav a').click(function(e) {
            e.preventDefault();

            $(this).closest('li').addClass('active').siblings('li').removeClass('active');

            $('.delivery_popup .content #'+ $(this).data()['delivery']).addClass('active').siblings('.table').removeClass('active');
        });
    }

    
    catalogItemCounter('.counter .count_field');    

    if($('#payment_type').length > 0) {
        
        if($('#payment_type').val() == "4") {
            $('#public_offer').show();
        } else {
            $('#public_offer').hide();
        }

        $('#payment_type').change(function(e) {
            if($(this).val() == "4") {
                $('#public_offer').show();
            } else {
                $('#public_offer').hide();
            }
        });
    }
    

});

function changedDelivery(value:any) {
    $(value).closest('.radio').siblings('.radio').removeClass('show');
    $(value).closest('.radio').addClass('show');

    $('#delivery_cost').html();

    $.ajax({
        type: 'POST',
        url: '/order/updateDelivery.php',
        data: 'price='+value.dataset.price+'&delivery_id='+value.value,
        success: function(res){
            let arr = JSON.parse(res);
            // console.log(arr); 

            $('#payment_type').html(arr['payments']);

            $('[name="delivery_price"]').val(arr['delivery']);
            $('#delivery_cost').html(arr['delivery']);
            $('.tot_number').html(arr['sum']);
        }
    });

    if( $(value).val() != '3') {
        $('#order_address').addClass('show');
        $('#order_address input').addClass('required');
        $('#outposts').removeClass('show');
    } else {
        $('#order_address').removeClass('show');
        $('#order_address input').removeClass('required');
        $('#outposts').addClass('show');
    }
}

function closePopups() {
    $('.popup_background').removeClass('show');    
    $('.popup').removeClass('show');  
}

function showSuccess(form_type:any) {
    closePopups();
    $('.popup_background').addClass('show');

    switch(form_type) {
        case 'callback':            
            $('#success_callback').addClass('show');
            break;
      
        case 'review':  
            $('#success_review').addClass('show');
            break;

        case 'added':  
            $('#success_added').addClass('show');
            break;

        case 'click':  
            $('#success_click').addClass('show');
            break;

        case 'addtocart':
            $('#success_addtocart').addClass('show');
            break;
      
        default:
            $('#success_callback').addClass('show');
            break;
      }


}

function catalogItemCounter(field:any){
          
    var fieldCount = function(el:any) {
    
        var 
        // Мин. значение
        min = el.data('min') || false,
        
        // Макс. значение
        max = el.data('max') || false, 
        
        // Кнопка уменьшения кол-ва
        dec = el.next('.dec'), 
        
        // Кнопка увеличения кол-ва
        inc = el.next().next('.inc');
        
        function init(el:any) {
            if(!el.attr('disabled')){
                dec.on('click', decrement);
                inc.on('click', increment);
                el.on('change input cut copy paste', check);
            }
        
            // Уменьшим значение
            function decrement() {
                var value = parseInt(el[0].value);
                value--;
        
                if(!min || value >= min) {
                    el[0].value = value;
                }
                $(el).trigger( "change" );
        
                //Summ(el[0].value);
                    
            };
        
            // Увеличим значение
            function increment() {
                var value = parseInt(el[0].value);
                    
                value++;
        
                if(!max || value <= max) {
                    el[0].value = value++;
                }
                $(el).trigger( "change" );
        
                //Summ(el[0].value);
        
            };

            function check() {
                var value = parseInt(el[0].value);
                
                if(value) {                    
                    el[0].value = value;
                    
                    if(value > max) {
                        //el[0].value = max;
                    }
    
                    if(value < min) {
                        el[0].value = min;
                    }
                } else {
                    el[0].value = min;
                }

                
            }
                
        }
        
        el.each(function() {
            init($(this));
        });
    };
    
    $(field).each(function(){
        fieldCount($(this));
    });    

}

function countUpdate(value:any, item_id:any) {
    $.ajax({
        type: 'POST',
        url: '/bitrix/templates/kult_sna/components/bitrix/sale.basket.basket/cart/countUpdate.php',
        data: 'quantity='+value+'&item_id='+item_id,
        success: function(res){
            let arr = JSON.parse(res);
            // console.log(arr); 

            if($('.cart_items').length) {
                $('#cart_sum').html(arr.sum);
                $('#cart_count').html(arr.count);
            }

            if(value == 0 && $('#success_addtocart').length) {
                let new_item = arr['new_item'];
                console.log();

                let html_object = $('#success_addtocart #added_item');
                html_object.find('.title').html(new_item['name']);
                html_object.find('.price .current_number').html(new_item['price']);
                html_object.find('#added_size').html(new_item['properties']['SIZE']);
                html_object.find('.color').css('background', new_item['color']);
                html_object.find('.img img').attr('src', new_item['preview_picture']);

                if(new_item['properties']['OLD_PRICE'] && new_item['properties']['SALE_PERCENT'] && new_item['properties']['SALE_PERCENT'] != 0 && new_item['properties']['SALE_PERCENT'] != undefined) {
                    html_object.find('.price .old').addClass('show').find('.number').html(new_item['properties']['OLD_PRICE']);
                    html_object.find('.sale').addClass('show').find('.number').html(new_item['properties']['SALE_PERCENT']);
                } else {
                    html_object.find('.price .old').removeClass('show');
                    html_object.find('.sale').removeClass('show');
                }

                $('#success_addtocart #added_quantity').html(arr.count);                
            }
            
            $('#top_basket').html(arr['top_basket']);
        }
    });      
}

function giftUpdate(value:any, old:any, cart_exist:boolean, url:any) {
    $.ajax({
        type: 'POST',
        url: url,
        data: 'offer_id='+value+'&old='+old+'&cart_exist='+cart_exist,
        success: function(res){
            // let arr = JSON.parse(res);
            // console.log(res); 

            if($('#basket_form li.gift').length) {
                $('#basket_form li.gift .img img').attr('src', res);
            }

            // if($('.cart_items').length) {
            //     $('#cart_sum').html(arr.sum);
            //     $('#cart_count').html(arr.count);
            // }
        }
    });      
}

function locationUpdated(location_id:any) {
    $.ajax({
        type: 'POST',
        url: '/order/updateLocation.php',
        data: 'location_id='+location_id,
        success: function(res){
            let arr = JSON.parse(res);
            // console.log(res); 

            $('#order_delivery').html(arr['deliveries']);
            $('#payment_type').html(arr['payments']);
            

            $('.delivery .radio input').change(function() {
                changedDelivery(this);
            });

            $('.delivery .radio input:checked').change();
        }
    });
}

      
    



